import './HomeHeader.css';
import React from 'react';
import ReactPlayer from 'react-player/youtube';
import { motion } from 'framer-motion';
import bgStarbucks from '../assets/img/bg_starbucks.png';
import { handleReady } from '../utils/handleReady';


const items = Array.from({length: 30}, () => 'showreel');

const HomeHeader = () => {
    const listItems = items.map((item, index) => {
        return <span className="item" key={index}>{item}</span>
    });

    return (
        <div className="main-header-container">
            <motion.div
                className="video-ticker-container"
                initial={{ y: -100 }}
                whileInView={{ y: 0 }}
                transition={{ type: "spring", delay: 0.2 }}
            >
                <div className="ticker-wrap">
                    <div className="ticker">
                        <span className="item-collection-1">
                            {listItems}
                        </span>
                        <span className="item-collection-2">
                            {listItems}
                        </span>
                    </div>
                </div>
                <div className="player-wrapper">
                    <ReactPlayer
                        className='react-player'
                        url='https://youtu.be/LQLgocYpIa0?si=l9IhSMeyPma97OVR'
                        controls={true}
                        muted={true}
                        loop={true}
                        width='100%'
                        height='100%'
                        style={{
                            backgroundImage: `url(${bgStarbucks})`,
                            backgroundSize: 'contain',
                        }}
                        onReady={handleReady}
                        config={{playerVars: {origin: window.location.href}}}
                    />
                </div>
                <div className="ticker-wrap">
                    <div className="ticker">
                        <span className="item-collection-1">
                        {listItems}
                        </span>
                        <span className="item-collection-2">
                        {listItems}
                        </span>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};
export default HomeHeader;
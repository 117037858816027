import Home from "./Home";
import About from "./About";
import { Routes, Route } from "react-router-dom";


const Main = () => {
    return (
        <main>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
            </Routes>
        </main>
    );
};
export default Main;
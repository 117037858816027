import './HomeBody.css';
import React from 'react'
import ReactPlayer from 'react-player/youtube';
import { motion } from 'framer-motion';
import { handleReady } from '../utils/handleReady';


const HomeBody = () => {
    return (
        <div className="main-body-container">
            <div className="middle">
                <motion.div
                    className='player-wrapper'
                    initial={{ x: -100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    <ReactPlayer
                        className='react-player'
                        url='https://youtu.be/axmhxRVYgLs?si=xuHsDWZTwZgnB-hk'
                        playing={true}
                        light={true}
                        controls={true}
                        muted={true}
                        loop={true}
                        width='100%'
                        height='100%'
                        config={{playerVars: {origin: window.location.href}}}
                    />
                </motion.div>
            </div>
            <div className="middle-end">
                <motion.div
                    className='player-wrapper'
                    initial={{ x: 100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    <ReactPlayer
                        className='react-player'
                        url='https://youtu.be/MjQK-nk41S4?si=ANqH7y0w-94XjwwA'
                        playing={true}
                        light={true}
                        controls={true}
                        muted={true}
                        loop={true}
                        width='100%'
                        height='100%'
                        config={{playerVars: {origin: window.location.href}}}
                    />
                </motion.div>
            </div>
            <div className="middle">
                <motion.div
                    className='player-wrapper'
                    initial={{ x: -100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    <ReactPlayer
                        className='react-player'
                        url='https://youtu.be/jCgLSnUwbxk?si=PQnPp3PHt6TxwOh8'
                        playing={true}
                        light={true}
                        controls={true}
                        muted={true}
                        loop={true}
                        width='100%'
                        height='100%'
                        config={{playerVars: {origin: window.location.href}}}
                    />
                </motion.div>
            </div>
            <div className="middle-end">
                <motion.div
                    className='player-wrapper'
                    initial={{ x: 100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    <ReactPlayer
                        className='react-player'
                        url='https://youtu.be/qIKjZdzs61E?si=IX27f8KMG0mv9QGn'
                        playing={true}
                        light={true}
                        controls={true}
                        muted={true}
                        loop={true}
                        width='100%'
                        height='100%'
                        config={{playerVars: {origin: window.location.href}}}
                    />
                </motion.div>
            </div>
            <div className="shorts-container">
                <motion.div
                    className='first-player-wrapper-mobile'
                    initial={{ x: -100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    <ReactPlayer
                        className='react-player'
                        url='https://youtube.com/shorts/sqteIXbRymM?si=8gSLhtqh9Mb7-Hzy'
                        onReady={handleReady}
                        controls={true}
                        muted={true}
                        loop={true}
                        width='100%'
                        height='100%'
                        config={{playerVars: {origin: window.location.href}}}
                    />
                </motion.div>
                <motion.div
                    className='second-player-wrapper-mobile'
                    initial={{ y: 100 }}
                    whileInView={{ y: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    <ReactPlayer
                        className='react-player'
                        url='https://youtube.com/shorts/ufUd_c15msQ?si=mcqvdTbua5JFJcYr'
                        onReady={handleReady}
                        controls={true}
                        muted={true}
                        loop={true}
                        width='100%'
                        height='100%'
                        config={{playerVars: {origin: window.location.href}}}
                    />
                </motion.div>
                <motion.div
                    className='third-player-wrapper-mobile'
                    initial={{ x: 100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    <ReactPlayer
                        className='react-player'
                        url='https://youtube.com/shorts/IJ2pG814N3s?si=UzDC5Ujd7CAWI1Ci'
                        onReady={handleReady}
                        controls={true}
                        muted={true}
                        loop={true}
                        width='100%'
                        height='100%'
                        config={{playerVars: {origin: window.location.href}}}
                    />
                </motion.div>
            </div>
            <div className="middle">
                <motion.div
                    className='player-wrapper'
                    initial={{ x: -100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    <ReactPlayer
                        className='react-player'
                        url='https://youtu.be/LQLgocYpIa0?si=szFDPbIFw2TEY1r6'
                        playing={true}
                        light={true}
                        controls={true}
                        muted={true}
                        loop={true}
                        width='100%'
                        height='100%'
                        config={{playerVars: {origin: window.location.href}}}
                    />
                </motion.div>
            </div>
            <div className="middle-end">
                <motion.div
                    className='player-wrapper'
                    initial={{ x: 100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    <ReactPlayer
                        className='react-player'
                        url='https://youtu.be/YVHK_NqpSJc?si=vddEKV69fBLdYZK7'
                        playing={true}
                        light={true}
                        controls={true}
                        muted={true}
                        loop={true}
                        width='100%'
                        height='100%'
                        config={{playerVars: {origin: window.location.href}}}
                    />
                </motion.div>
            </div>
            <div className="middle">
                <motion.div
                    className='player-wrapper'
                    initial={{ x: -100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    <ReactPlayer
                        className='react-player'
                        url='https://youtu.be/aG0CPGpKA3w?si=eBEQv8quGOER7ui8'
                        playing={true}
                        light={true}
                        controls={true}
                        muted={true}
                        loop={true}
                        width='100%'
                        height='100%'
                        config={{playerVars: {origin: window.location.href}}}
                    />
                </motion.div>
            </div>
            <div className="middle-end">
                <motion.div
                    className='player-wrapper'
                    initial={{ x: 100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    <ReactPlayer
                        className='react-player'
                        url='https://youtu.be/ubn5lIo34aA?si=fXCuHNZL86-A2M5n'
                        playing={true}
                        light={true}
                        controls={true}
                        muted={true}
                        loop={true}
                        width='100%'
                        height='100%'
                        config={{playerVars: {origin: window.location.href}}}
                    />
                </motion.div>
            </div>
        </div>
    );
};
export default HomeBody;
import './Footer.css';
import linkedin from '../assets/icons/linkedin.png'
import instagram from '../assets/icons/instagram.png'
import discord from '../assets/icons/discord.png'
import whats from '../assets/icons/whats.png'
import email from '../assets/icons/email.png'
import { motion } from 'framer-motion';
import { useEffect, useRef } from "react";



const Footer = () => {
    const footerRef = useRef(null);

    useEffect(() => {
        let prevScrollPos = window.scrollY;

        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            const footerElement = footerRef.current;
            const scrollMaxY =  document.documentElement.scrollHeight - document.documentElement.clientHeight - footerElement.clientHeight;

            if (!footerElement) {
                return;
            };
            if (prevScrollPos < currentScrollPos || currentScrollPos >= scrollMaxY) {
                footerElement.style.bottom = '0px';
            } else {
                footerElement.style.bottom = '-200px';
            };
            prevScrollPos = currentScrollPos;
        };
        window.addEventListener('scroll', handleScroll)
        return () => {
          window.removeEventListener('scroll', handleScroll)
        }
    },[]);

    return (
        <>
        <div className='footer-position-correction'></div>
        <footer ref={footerRef}>
            <div className='social-media-icons'>
                <ul>
                    <motion.li whileHover={{scale: 1.2}}><a href="https://www.linkedin.com/in/vanbemotion/" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt='linkedin' /></a></motion.li>
                    <motion.li whileHover={{scale: 1.2}}><a href="https://www.instagram.com/van.bemotion/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt='instagram' /></a></motion.li>
                    <motion.li whileHover={{scale: 1.2}}><a href="https://discord.gg/bbaWMEcF5r" target="_blank" rel="noopener noreferrer"><img src={discord} alt='discord' /></a></motion.li>
                    <motion.li whileHover={{scale: 1.2}}><a href="https://wa.me/555193929239?text=Olá,%20peguei%20seu%20contato%20no%20site" target="_blank" rel="noopener noreferrer"><img src={whats} alt='whatsapp' /></a></motion.li>
                    <motion.li whileHover={{scale: 1.2}}><a href="mailto:van.bemotion@gmail.com"><img src={email} alt='email' /></a></motion.li>
                </ul>
            </div>
        </footer>
        </>
    );
};
export default Footer;
import './About.css';
import { useState, useEffect } from "react";
import { motion } from 'framer-motion';
import vanessa from '../assets/img/van_glitch.png';


const About = () => {
    const [aboutHeight, setAboutHeight] = useState(undefined);

    useEffect(() => {
        const handleResize = () => {
            setAboutHeight(window.innerHeight - document.getElementsByTagName('header')[0].clientHeight - document.getElementsByTagName('footer')[0].clientHeight);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="about-container" style={{height: '100%', minHeight: aboutHeight}}>
            <motion.div
                className="image-container"
                initial={{ y: -100 }}
                whileInView={{ y: 0 }}
                transition={{ type: "spring", delay: 0.2 }}
            >
                <img src={vanessa} alt='vanessa-motion-designer' />
            </motion.div>
            <div className="about-content">
                <motion.p
                    initial={{ x: -100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    Olá! Sou Vanessa, uma profissional apaixonada por arte em movimento.<br/>
                    Graduada em Marketing pela Universidade Estácio de Sá, no Rio de Janeiro, adquiri uma bagagem diversificada, atuando na criação e estúdio de agências, além de trabalhar no marketing de marcas relevantes.
                </motion.p>
                <motion.p
                    initial={{ x: 100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    Atualmente, meu foco está na criação de animações inteligentes para clientes de diversos setores.<br />
                    A fundação da Be Motion é um marco importante nessa jornada.<br />
                    Somos uma empresa especializada em Motion Design para o mercado publicitário, dedicados a produzir animações, vinhetas envolventes, e trazer marcas e logos à vida.
                </motion.p>
                <motion.p
                    initial={{ x: -100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    Acreditamos que cada projeto tem uma história única para contar, e é essa crença que nos impulsiona a colaborar de forma próxima e criativa.<br />
                    Estamos ansiosos para ser parte do seu projeto! Entre em contato, compartilhe suas visões e solicite um orçamento.<br />
                    Juntos, daremos vida e movimento às suas ideias.
                </motion.p>
            </div>
        </div>
    );
};
export default About;
import HomeHeader from './HomeHeader';
import HomeBody from './HomeBody';


const Home = () => {
    return (
        <>
            <HomeHeader />
            <HomeBody />
        </>
    );
};
export default Home;